import { lazy, Suspense } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import "./assets/sass/mixins/global.scss";
import PageLoader from "./Components/PageLoader";

const PrivacyPolicy = lazy(() => import("./Pages/PrivacyPolicy/Privacy"));
const TermsOFus = lazy(() => import("./Pages/TermsOFUse/Terms"));
const HomePage = lazy(() => import("./Pages/Home"));
const FeedbackPage = lazy(() => import("./Pages/Feedback"));
const LoginPage = lazy(() => import("./Pages/Auth/Login"));
const TrackerPage = lazy(() => import("./Pages/Tracker"));
const UserProfile = lazy(() => import("./Pages/UserProfile/ProfilePage"));
const Settings = lazy(() => import("./Pages/Settings"));
const DownloadPage = lazy(() => import("./Pages/Download"));
const InstructionPage = lazy(() => import("./Pages/Instruction"));

const DashboardPage = lazy(() => import("./Pages/Admin/Dashboard"));
const UserPage = lazy(() => import("./Pages/Admin/User"));
const UserDetailsPage = lazy(
  () => import("./Components/Admin/Dashboard/User/UserDetails")
);
const SkillsPage = lazy(() => import("./Pages/Admin/Skills"));
const SkillDetailsPage = lazy(
  () => import("./Components/Admin/Dashboard/Skills/SkillDetails/SkillDetails")
);
const LeaderboardPage = lazy(
  () =>
    import(
      "./Components/Admin/Dashboard/Skills/SkillDetails/SkillLeaderBoard/SkillLeaderboard"
    )
);
const TasksPage = lazy(() => import("./Pages/Admin/Tasks"));
const TasksDetailsPage = lazy(
  () => import("./Pages/Admin/Tasks/TaskDetails/TaskDetails")
);
const WorkSessionsPage = lazy(() => import("./Pages/Admin/WorkSessions"));
const WorkSessionsDetailPage = lazy(
  () =>
    import("./Pages/Admin/WorkSessions/WorkSessionDetails/WorkSessionDetails")
);
const TaskBuilderPage = lazy(() => import("./Pages/Admin/TaskBuilder"));
const App = () => {
  return (
    <Suspense fallback={<PageLoader />}>
      <Router>
        <Switch>
          <Route exact path="/" component={HomePage} />
          <Route exact path="/privacy-policy" component={PrivacyPolicy} />
          <Route exact path="/terms-and-conditions" component={TermsOFus} />
          <Route exact path="/login" component={LoginPage} />

          {/* authentication require  */}

          <Route exact path="/tracker" component={TrackerPage} />
          <Route path="/instruction" component={InstructionPage} />
          <Route exact path="/dashboard" component={FeedbackPage} />
          <Route exact path="/userprofile" component={UserProfile} />
          <Route exact path="/u/:username" component={UserProfile} />
          <Route exact path="/my-profile" component={UserProfile} />
          <Route exact path="/settings" component={Settings} />
          <Route exact path="/download" component={DownloadPage} />
          <Route exact path="/admin-dashboard" component={DashboardPage} />
          <Route exact path="/admin-users" component={UserPage} />
          <Route
            exact
            path="/admin-users/userdetails"
            component={UserDetailsPage}
          />
          <Route exact path="/admin-skills" component={SkillsPage} />
          <Route
            exact
            path="/admin-skills/skilldetails"
            component={SkillDetailsPage}
          />
          <Route
            exact
            path="/admin-skills/skilldetails/leaderboard"
            component={LeaderboardPage}
          />
          <Route exact path="/admin-tasks" component={TasksPage} />
          <Route
            exact
            path="/admin-tasks/taskdetails"
            component={TasksDetailsPage}
          />
          <Route
            exact
            path="/admin-worksessions"
            component={WorkSessionsPage}
          />
          <Route
            exact
            path="/admin-worksessions/workdetails"
            component={WorkSessionsDetailPage}
          />
          <Route exact path="/admin-taskbuilder" component={TaskBuilderPage} />
        </Switch>
      </Router>
    </Suspense>
  );
};

export default App;
