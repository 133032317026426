import { configureStore } from "@reduxjs/toolkit";

import auth from "./Reducers/Auth";
import tracker from "./Reducers/Tracker";

export const store = configureStore({
    reducer: {
        auth: auth,
        tracker: tracker,
    },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
