const appConfig = {
  app: process.env.REACT_APP_APP_ID,
  apiUrl: process.env.REACT_APP_API_URL,
  accountUrl: process.env.REACT_APP_ACCOUNT_URL,
  rootUrl: process.env.REACT_APP_ROOT_URL,
  domain: process.env.REACT_APP_DOMAIN,
  secure: process.env.REACT_APP_SECURE ?? false,
  expiration: 2, // 2 days
  SUPABASE_URL: process.env.REACT_APP_SUPABASE_URL ?? "",
  SUPABASE_SERVICE_ROLE_KEY:
    process.env.REACT_APP_SUPABASE_SERVICE_ROLE_KEY ?? "",
};

export default appConfig;
