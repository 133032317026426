import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export enum TrackerStatus {
  START = "start",
  STOP = "stop",
  PAUSE = "pause",
}

export interface TrackerData {
  loading: boolean;
  status: TrackerStatus;
  bookingId: string | undefined;
  start: Date | undefined;
  taskId: string | null;
  checklist: any;
  task: any;
  viewOnly: boolean;
  success: any;
  successid: any;
  Completeid: any;
  CompleteSuccess: boolean;
  NextId: any;
}

const initialState: TrackerData = {
  loading: false,
  status: TrackerStatus.STOP,
  bookingId: undefined,
  start: undefined,
  taskId: null,
  checklist: null,
  task: null,
  viewOnly: false,
  success: false,
  successid: null,
  Completeid: undefined,
  CompleteSuccess: false,
  NextId: null,
};

export const trackerSlice = createSlice({
  name: "tracker",
  initialState,
  reducers: {
    reset: (state) => {
      state.loading = false;
      state.status = TrackerStatus.STOP;
      state.bookingId = undefined;
      state.start = undefined;
      state.taskId = null;
      state.checklist = null;
      state.task = null;
      state.viewOnly = false;
      state.Completeid = undefined;
    },
    start: (state) => {
      state.status = TrackerStatus.START;
      state.Completeid = undefined;
    },
    stop: (state) => {
      state.status = TrackerStatus.STOP;
      state.success = false;
      state.CompleteSuccess = false;
      state.start = undefined;
      state.Completeid = undefined;
    },
    pause: (state) => {
      state.status = TrackerStatus.PAUSE;
    },
    processing: (state) => {
      state.loading = true;
    },
    finished: (state) => {
      state.loading = false;
    },
    setData: (
      state,
      action: PayloadAction<{
        start: Date;
        bookingId: string | undefined;
        taskId: string | null;
        checklist: any;
      }>
    ) => {
      const { taskId, checklist, bookingId, start } = action.payload;
      state.loading = false;
      state.bookingId = bookingId;
      state.taskId = taskId;
      state.checklist = checklist;
      state.start = start;
      if (checklist) {
        const task = checklist.tasks.find((el: any) => el._id === taskId);
        if (task) state.task = task;
      }
    },

    // Complete Task

    setCompleteTask: (
      state,
      action: PayloadAction<{
        CompleteSuccess: any;
        Completeid: any;
      }>
    ) => {
      const { CompleteSuccess, Completeid } = action.payload;

      state.CompleteSuccess = CompleteSuccess;
      state.Completeid = Completeid;
      //   let i = 1;

      let taskId = Completeid;
      let currentId = state.checklist.tasks.findIndex(
        (index) => index.taskId === taskId
      );

      const skipTask = (tasksList, currentTaskIndex) => {
        state.loading = true;
        if (tasksList[currentTaskIndex + 1]?.isCompleted === true) {
          currentId++;
          skipTask(tasksList, currentId);
          state.loading = false;

          //   return;
        } else {
          state.NextId = tasksList[currentTaskIndex + 1];
          state.task = tasksList[currentTaskIndex + 1];
          state.loading = false;

          return;
        }
      };

      skipTask(state?.checklist?.tasks, currentId);

      let lastElement = 0;
      const LastSkip = (checkSkip: any, Lid: any) => {
        if (checkSkip?.length - 1 === currentId) {
          state.status = TrackerStatus.STOP;
          state.CompleteSuccess = false;
          state.start = undefined;

          if (checkSkip[Lid]?.isCompleted === true) {
            lastElement++;
            state.NextId = checkSkip[Lid];
            LastSkip(checkSkip, lastElement);
          } else {
            state.NextId = checkSkip[Lid];
            state.task = checkSkip[Lid];
          }
        }
      };

      LastSkip(state?.checklist?.tasks, currentId);
    },

    // end Complete Task

    setSkipData: (
      state,
      action: PayloadAction<{
        success: any;
        successid: any;
      }>
    ) => {
      const { success, successid } = action.payload;

      state.success = success;
      state.successid = successid;
      //   let i = 1;

      let taskId = successid;
      let currentId = state.checklist.tasks.findIndex(
        (index) => index.taskId === taskId
      );

      const skipTask = (tasksList, currentTaskIndex) => {
        state.loading = true;
        if (tasksList[currentTaskIndex + 1]?.isCompleted === true) {
          currentId++;
          skipTask(tasksList, currentId);
          state.loading = false;
          state.Completeid = undefined;

          //   return;
        } else {
          state.NextId = tasksList[currentTaskIndex + 1];
          state.task = tasksList[currentTaskIndex + 1];
          state.loading = false;
          state.Completeid = undefined;

          return;
        }
      };

      skipTask(state?.checklist?.tasks, currentId);

      let lastElement = 0;
      const LastSkip = (checkSkip: any, Lid: any) => {
        if (checkSkip?.length - 1 === currentId) {
          state.status = TrackerStatus.STOP;
          state.success = false;
          state.start = undefined;
          state.Completeid = undefined;

          if (checkSkip[Lid]?.isCompleted === true) {
            lastElement++;
            state.NextId = checkSkip[Lid];
            LastSkip(checkSkip, lastElement);
            state.Completeid = undefined;
          } else {
            state.NextId = checkSkip[Lid];
            state.task = checkSkip[Lid];
            state.Completeid = undefined;
          }
        }
      };

      LastSkip(state?.checklist?.tasks, currentId);
    },

    setTask: (state, action: PayloadAction<{ taskId: string | null }>) => {
      const { taskId } = action.payload;
      const task = state.checklist.tasks.find((el: any) => el._id === taskId);
      if (task) state.task = task;
      state.viewOnly = state.taskId !== taskId;
    },
    updateAnswers: (
      state,
      action: PayloadAction<{ taskId: string; field: string; value: any }>
    ) => {
      const { taskId, field, value } = action.payload;
      const newData = { name: field, value: value };
      const task = state.checklist.answers
        ? state.checklist.answers.find((e) => e.task === taskId)
        : { data: [newData], task: taskId };
      if (task) {
        const data = task.data.filter((e) => e.name !== field);
        task.data = [...data, newData];
        const temp = state.checklist.answers
          ? state.checklist.answers?.filter((e) => e.task !== taskId)
          : [];
        state.checklist.answers = [...temp, task];
      }
    },
  },
});

export const {
  start,
  stop,
  pause,
  setData,
  setTask,
  updateAnswers,
  processing,
  finished,
  reset,
  setSkipData,
  setCompleteTask,
} = trackerSlice.actions;

export default trackerSlice.reducer;
